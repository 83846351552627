th {
  background: white;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

td:first-child {
  background: white;
  position: sticky;
  left: 0;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

body {
  margin-left: 30px;
  margin-right: 30px;
}
table {
  border-collapse: collapse;
}

th {
  text-align: left;
  padding-right: 20px;
}

td {
  padding-left: 4px;
  border-left: 1px solid rgb(192, 192, 192);
}
tr {
  border-bottom: 1px solid rgb(192, 192, 192);
}

td:first-child,
th:first-child {
  border-left: none;
}
tr:last-child {
  border-bottom: none;
}
table {
  margin-bottom: 20px;
}

li {
  margin-top:7px;
  margin-bottom: 7px;
}

table td:nth-child(5), table td:nth-child(8) {
  background-color:rgb(50, 194, 100,0.3);
}
table td:nth-child(9) {
  background-color:rgb(74, 141, 211,0.3);
  color:black;
}

table td:nth-child(9) a {
  color:black;
}

/* Sortable table reset */
.sortable thead th {
    background: inherit !important;
    
    color: initial !important;
    font-weight: initial !important;
    text-align: initial !important;
    text-transform: initial !important;
    vertical-align: initial !important;
    white-space: initial !important;
}